function menuActions() {
  var $navTrigger = document.querySelector("[data-navigation-trigger]");
  var $navTarget = document.querySelector("[data-navigation-target]");
  if ($navTrigger && $navTarget) {
    var $navLinks = $navTarget.querySelectorAll("a");

    $navTrigger.addEventListener("click", e => {
      $navTrigger.classList.toggle("is-active");
      $navTarget.classList.toggle("is-active");
      document.body.classList.toggle("menu-open");

      e.preventDefault();
    });

    $navLinks.forEach($l => {
      $l.addEventListener("click", e => {
        $navTrigger.classList.toggle("is-active");
        $navTarget.classList.toggle("is-active");
      });
    });
  }
}

export default menuActions;
