import "../scss/app.scss";

// SVGs to make into sprite, paths relative to app.js before move
var __svg__ = {
	path: "../svgs/sprite/*.svg",
	name: "../katherinescholes/jinja2/svgs/sprite.svg"
};

// Polyfill all the things...
import "whatwg-fetch";
// For anything not-DOM (eg String.repeat, Promise, Array.fill etc) use core-js.
// See available options here -> https://github.com/zloirock/core-js#features
import "core-js/es/array/from";
import "core-js/features/promise";

// We manually include the DOM polyfills we believe are necesary.
import applyDomPolyfills from "./includes/dompolyfills";
import "date-input-polyfill";

import { AjaxForm } from "@neonjungle/birdseed/forms/ajax";

applyDomPolyfills();

import menuActions from "./includes/menuActions";

document.addEventListener("DOMContentLoaded", function() {
	menuActions();

	let mailchimpForm = document.querySelector("[data-mailchimp-form]");
	if (mailchimpForm) new AjaxForm(mailchimpForm);
});
